import React from "react"
import { Layout } from "../components/Layout"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import cover from "../img/releases/espero-cover.png"

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Tüdel Beats - Home</title>
      </Helmet>

      <Layout>
        <section className="section">
          <div className="container">
            <h1 className="title">Tüdel Beats</h1>
            <h2 className="subtitle">
              is a music lover and producer from Berlin, crafting Instrumental
              Hip Hop, Lo-Fi and Boombap beats.{" "}
              <Link to="/about">More about me &#10132;</Link>
            </h2>
          </div>
        </section>

        <section className="hero is-primary">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-one-fifth">
                  <Link to="/releases/espero">
                    <figure className="image is-square">
                      <img src={cover} alt="Espero Cover" />
                    </figure>
                  </Link>
                </div>
                <div className="column">
                  <h1 className="title">New Release: Espero</h1>
                  <p className="content">
                    This is my second EP called Espero. I've worked on it during
                    the Covid-19 lockdown times. It reflects a bit the hope for
                    a new normal we should never give up.{" "}
                    <Link className="has-text-link-light" to="/releases/espero">
                      Read more &#10132;
                    </Link>
                  </p>
                  <p className="content">
                    Get it on{" "}
                    <a
                      className="has-text-link-light"
                      href="https://tuedelbeats.bandcamp.com/album/espero"
                    >
                      Bandcamp
                    </a>{" "}
                    or listen to it on your favorite streaming service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default IndexPage
